import {DOCUMENT} from "@angular/common";
import {Inject, Injectable} from "@angular/core";
import {WhiteLabelSettingsModel} from "@atlas-workspace/shared/models";

@Injectable()
export class ClientWhiteLabelService {
  private readonly sheet!: CSSStyleSheet;
  private readonly body!: HTMLElement;
  private readonly bodyClass = 'white-label';

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    this.sheet = new CSSStyleSheet();
    this.body = this.document.body;
  }

  generateStyleSheet(data: WhiteLabelSettingsModel): void {
    this.sheet.replaceSync(`
      html:has(body.${this.bodyClass}) {
        --primary-text-color: #${data.textColor};
        --accent-color: #${data.accentColor};
        --border-color: #${data.borderColor};
        --border-color-transparent: #${data.borderColor};
        --button-border-radius: ${data.buttonCornerRadius}px;
        --button-text-color: #${data.buttonTextColor};
        --background-layout: #${data.backgroundColor};
      }
  `);
    this.document.adoptedStyleSheets = [this.sheet];
    this.bodyWhiteLabelAddClass();
  }

  bodyWhiteLabelAddClass(): void {
    if (!this.body.classList.contains(this.bodyClass)) {
      this.body.classList.add(this.bodyClass);
    }
  }

  bodyWhiteLabelRemoveClass(): void {
    if (this.body.classList.contains(this.bodyClass)) {
      this.body.classList.remove(this.bodyClass);
    }
  }
}
