import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpAuthInterceptor, NotFoundPageComponent } from '@atlas-workspace/shared/ui';

import { AuthGuard } from './modules/shared/guards/auth.guard';

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'nobels/auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    data: { isNobels: true },
  },
  {
    path: 'base-layout',
    loadChildren: () =>
      import('./modules/base-layout/base-layout.module').then((m) => m.BaseLayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'full-layout',
    loadChildren: () =>
      import('./modules/full-layout/full-layout.module').then((m) => m.FullLayoutModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'options',
    loadChildren: () =>
      import('./modules/options-iframe/options-iframe.module').then((m) => m.OptionsIframeModule),

  },
   // Nobel routes
   {
    path: 'nobels/base-layout',
    loadChildren: () =>
      import('./modules/base-layout/base-layout.module').then((m) => m.BaseLayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'nobels/full-layout',
    loadChildren: () =>
      import('./modules/full-layout/full-layout.module').then((m) => m.FullLayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: '404',
    component: NotFoundPageComponent,
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // enableTracing: true
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true }],
})
export class AppRoutingModule {}
